<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">吧台管理</div>
        <div class="rb">
          <img src="@/assets/add.png" class="icon" />
          <router-link to="/position/add">
            <div class="text">添加</div>
          </router-link>
        </div>
        
      </div>
      <div class="content">
        <div class="items">
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>
          <div class="item" v-for="item in items" :key="item.id">
            <div class="box">
              <div class="name">{{ item.client_name }}</div>
              <div class="unames">负责人：{{ guardNames(item) }}</div>
            </div>
            <div class="box">
              <img src="@/assets/del.png" @click="onDelete(item)" class="del active" />
              <div class="view active" @click="onItemClick(item)">查看二维码</div>
            </div>
          </div>
        </div>
      </div>
      <div class="xg">      
        <div class="button primary mt" @click="onBindPost()">密码审批方式设置</div>  
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
export default {
  created() {
    this.query();
  },
  data() {
    return {
      items: []
    };
  },
  methods: {
    async query() {
      let { data } = await this.axios.get('/ctid/tlService/selectdoor');
      this.items = data.data;
    },
    guardNames(item) {
      let users = get(item, 'listusermw') || [];
      if (users.length === 0) {
        return '暂无';
      }
      return map(users, x => x.user_name).join(', ');
    },
    onItemClick(item) {
      this.$router.push('/position/detail/' + item.client_id);
    },
    onDelete(item) {
      Dialog.confirm({
        message: '是否删除岗位[' + item.client_name + ']?'
      }).then(async () => {
        let { data } = await this.axios.post('/ctid/tlService/deletedoor', qs.stringify({ client_id: item.client_id }));
        if (data.flag === '0') {
          Toast('删除成功!');
          this.query();
        } else {
          Dialog({ message: data.message });
        }
      });
    },
    onBindPost() {
      Dialog.confirm({
        message: '确认进行审批方式设置吗?'
      }).then(async () =>{       
        this.$router.push('/position/bind_post');
      });
    }
    
  }
};
</script>

<style lang="less" scoped>
.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 60px;
    color: #fff;
    font-size: 44px;
    float: left;
  }
  
  
  .rb {
    height: 64px;
    width: 214px;
    float: right;
    overflow: hidden;
    background: #7456ff;
    border-radius: 38px;
    .icon {
      width: 48px;
      height: 48px;
      margin: 8px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
}

.rb1 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 36px;
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
    }
    .del {
      width: 64px;
      height: 64px;
      display: block;
      float: right;
      margin-top: 18px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
</style>
